.tc__feesTooltip {
  position: absolute;
  padding: 14px 10px 14px 10px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 999;
  min-width: 300px;
  cursor: auto;
}

.tc__feesTooltip-row {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
