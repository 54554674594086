.tc__main {
  display: flex;
  flex: 1;
  height: 100%;
  border-radius: 5px;
  background: rgba(252, 252, 252, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.tc__dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tc__dashboard__row {
    display: flex;
    padding: 0 0 0 15px;
    box-sizing: border-box;

    &:first-child {
      padding-top: 15px;
    }

    &:last-child {
      padding-bottom: 15px;
    }
  }
}
