@import '../../constants.scss';

.tc__sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 25px;
  padding-right: 15px;
  border-radius: 5px 0px 0px 5px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  background: $card-background;
  box-sizing: border-box;
  width: 170px;
}

.tc__sidebar-section-title {
  padding-left: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.tc__sidebar-section-title {
  align-self: flex-start;
}

.tc__sidebar-view-item {
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: 40px;
  padding-left: 20px;
  cursor: pointer;
  width: 100%;
  border-radius: 0px 15px 15px 0px;
  box-sizing: border-box;
  padding-right: 10px;

  > div {
    transition: font-size 150ms linear;
  }

  &:hover:not(.selected) {
    & > div {
      font-size: 17px !important;
    }
  }

  &.selected {
    background: linear-gradient(97.53deg, #4ea3c8 0%, #285a95 100%);
  }
}

.tc__sidebar-pool-link {
  cursor: pointer;
}
