@import '../shared/style.scss';

.tc__currentPosition {
  @include cardContainer;
  background: rgba(255, 255, 255, 0.5); // TODO add to constants
}

.tc__currentPosition__body {
  @include cardBody;

  .tc__currentPosition__body__bar {
    width: 100%;
    margin: 30px auto 10px;
    height: 22px;
    border-radius: 5px;
    display: flex;
    overflow: hidden;

    .tc__currentPosition__body__bar__1 {
      background-color: #f5ac37; // TODO add to constants
    }

    .tc__currentPosition__body__bar__2 {
      background-color: #f5ac37; // TODO add to constants
      background-image: url('../../images/pattern-principals.svg');
    }

    .tc__currentPosition__body__bar__3 {
      background-color: #288195; // TODO add to constants
      background-image: url('../../images/pattern-yields.svg');
    }

    .tc__currentPosition__body__bar__4 {
      background-color: #288195; // TODO add to constants
    }
  }

  .tc__currentPosition__body__item {
    @include cardBodyItem;

    > .tc__currentPosition__body__item__with-icon {
      display: flex;
      align-items: center;
    }
  }

  .tc__currentPosition__icon {
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: 15px;
    width: 12px;
    height: 12px;
    border-radius: 12px;

    &:after {
      position: absolute;
      top: 0;
      left: 6px;
      content: '';
      height: 12px;
      width: 12px;
      border-radius: 12px;
    }
  }

  .tc__currentPosition__icon-principals {
    background: #f5ac37; // TODO add to constants

    &:after {
      background: #ffe2b2; // TODO add to constants
    }
  }

  .tc__currentPosition__icon-yields {
    background: #288195; // TODO add to constants

    &:after {
      background: #5aaec0; // TODO add to constants
    }
  }
}
