@import '../../../constants.scss';

.tc__currency-switch {
  margin: auto 20px;
}

.tc__switch {
  position: relative;
  height: 25px;
  width: 130px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  > .tc__switch__label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 65px;
    z-index: 1;
    user-select: none;

    &:not(.tc__switch__label-selected):hover > div {
      color: $link-color !important;
    }
  }

  > .tc__switch__selector {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(97.53deg, #4ea3c8 0%, #285a95 100%);
    border-radius: 30px;
    height: 25px;
    width: 65px;
    z-index: 0;
    transition: left 0.25s ease-out;
  }
}
