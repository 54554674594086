.tc__operations {
  display: flex;
  flex-grow: 1;
  height: 100%;
  border-radius: 5px;
  background: rgba(252, 252, 252, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.tc__operations-sidebar {
  display: flex;
  flex-direction: column;
}

.tc__operations-cards-container {
  display: flex;
  flex-grow: 1;
  padding-top: 15px;
  padding-left: 15px;
}

.tc__operations-poolData {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tc__operations-poolStats {
  display: flex;
}

.tc__operations-poolUserStats {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  min-width: 380px;
}
