@import '../../constants.scss';

$padding: 20px;

.tf__dashboard__section__container {
  display: flex;
  padding: 0;
  border-radius: 5px;
  padding: $padding;
  @include background-level-1;

  &[hidden] {
    display: none;
  }
}

.tf__detail__section__container {
  display: flex;
  padding: 0;
  border-radius: 5px;

  &[hidden] {
    opacity: 0;
    z-index: -99;
  }
}

.tc__dashboard__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $text-color;
    margin: 10px 0px 10px 0px;
  }
}

.tc__dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .tc__dashboard__header__actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      cursor: pointer;

      > svg {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.tf__dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    overflow: visible;
  }

  .tf__dashboard__grid {
    .MuiTable-root:nth-child(2) {
      border-spacing: 0 10px;
    }

    .tf__dashboard__filter__row {
      > th {
        background-color: transparent;

        .tf__dashboard__filter__chip {
          margin: 0 8px;
        }

        .tf__dashboard__filter__chip-select {
          margin-top: 0;
        }
      }
    }

    table {
      background-color: transparent;
    }

    tbody {
      > tr {
        height: 80px;
        padding: 10px 0;
      }
    }

    .tf__dashboard__body__row {
      @include background-level-2;
      border-radius: 5px 5px 5px 5px;

      > td {
        border-bottom-color: transparent;
        font-size: 16px;
        padding-left: 0;

        &:first-child {
          border-radius: 5px 0 0 5px;
          padding-left: 20px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
          padding-right: 10px;
        }
      }

      &.tf__dashboard__body__row-child {
        @include background-level-3;

        .tf__dashboard__body__trade {
          display: flex;
          justify-content: center;
        }
      }
    }

    .tf__dashboard__header__row {
      > th {
        background-color: transparent;
      }

      .tf__dashboard__header__cell {
        padding: 0px 0px 3px 0px;
        border-bottom: none;

        &.tf__dashboard__header__maturity {
          padding-right: 8px;
        }

        &.tf__dashboard__header__fixedAPR {
          padding-right: 8px;
        }

        &.tf__dashboard__header__variableAPY {
          padding-right: 8px;
        }

        &.tf__dashboard__header__TVL {
          padding-right: 8px;
        }

        &.tf__dashboard__header__presentValue {
          padding-right: 8px;
        }

        &.tf__dashboard__header__availableToDeposit {
          padding-right: 8px;
        }

        .tf__dashboard__header-info-icon-container {
          margin-right: 5px;
        }

        button.MuiIconButton-root {
          > span {
            > svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        > div > button {
          padding: 0px;
        }
      }
    }

    .tf__dashboard__body__apy {
      display: flex;
      justify-content: flex-end;

      .tf__dashboard__body__apy-value {
        display: flex;
        align-items: flex-end;
      }
    }

    .tf__dashboard__trade-button {
      display: flex;
      justify-content: center;
      width: 100%;

      .MuiButton-containedSizeSmall {
        padding: 2px 8px;
      }

      .MuiButton-root {
        color: #ffffff;
        padding: 10px 15px 10px 15px;
        text-transform: none;
        background-color: $accent-color-3;
      }
    }

    .tf__dashboard__asset-ticker {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 5px;
      }
    }

    .tf__dashboard__parent-toggle-icon {
      width: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .tf__dashboard__parent-token-ticker {
      width: 65px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
