@import '../shared/style.scss';

.tc__profitLoss {
  @include cardContainer;
  background: rgba(255, 255, 255, 0.5); // TODO add to constants
  flex-grow: 0;
}

.tc__profitLossChartTooltip {
  background: linear-gradient(0deg, #ffffff -0.55%, #f3f7f9 100%);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px 25px 8px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
