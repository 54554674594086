.tc__tokenPairIcon-parent {
  position: relative;
  width: 40px;
  height: 40px;
}

.tf__tokenPairIcon-child {
  position: absolute;
  width: 27px;
  height: 27px;
  bottom: 0px;
  right: -12px;
}
