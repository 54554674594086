@import '../../constants.scss';

.tf__progress-bar {
  width: 100%;
  margin: 5px auto;

  .tf__progress-bar__outer {
    position: relative;
    height: 5px;
    width: 100%;
    border-radius: 3px;
    background-color: $ui-element-color;

    .tf__progress-bar__inner {
      top: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      background-color: $accent-color-2;
    }
  }
}
